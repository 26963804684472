import Empty from "./views/Empty.vue";
import LoginPage from "./views/LoginPage.vue";
import PrintLayout from "./views/PrintLayout.vue";
import Register from "./views/Register.vue";
import Router from "vue-router";
import Vue from "vue";
import WelcomePage from "./views/WelcomePage.vue";
import isPublicSite from "./is_public_site";

Vue.use(Router);

function getRoutes() {
  if (isPublicSite) {
    return [
      {
        path: "/login",
        name: "login",
        beforeEnter: () => {
          window.location.href = "https://app.addedvalue.co.il/login";
        }
      },
      {
        path: "/enter",
        name: "user-home",
        beforeEnter: () => {
          window.location.href = "https://app.addedvalue.co.il/";
        }
      },
      {
        path: "/register",
        name: "register",
        beforeEnter: () => {
          window.location.href = "https://app.addedvalue.co.il/register";
        }
      },
      {
        path: "",
        name: "site-home",
        component: () =>
          import(/* webpackChunkName: "site" */ "./views/SiteLayout"),
        children: [
          {
            path: "/",
            name: "site-homepage",
            props: true,
            component: () =>
              import(/* webpackChunkName: "site" */ "./views/site/Homepage")
          }
        ]
      },
      {
        path: "*",
        redirect: "/"
      }
    ];
  }

  return  [
    {
      path: "/login",
      name: "login",
      meta: {
        title: "כניסה למערכת | AddedValue",
        menus: false
      },
      component: LoginPage
    },
    {
      path: "/invite-login",
      name: "invite-login",
      meta: {
        title: "כניסה למערכת | AddedValue",
        menus: false
      },
      component: WelcomePage
    },
    {
      path: "/welcome",
      name: "welcome",
      meta: {
        title: "כניסה למערכת | AddedValue",
        menus: false
      },
      component: WelcomePage
    },
    {
      path: "/admin",
      component: () =>
        import(/* webpackChunkName: "admin" */ "./views/AdminLayout"),
      children: [
        {
          path: "/",
          name: "admin-home",
          component: () =>
            import(/* webpackChunkName: "admin" */ "./views/admin/Home"),
          meta: { title: "עמוד ראשי | AddedValue" }
        },
        {
          path: "mislaka/",
          name: "admin-mislaka",
          component: () =>
            import(/* webpackChunkName: "admin" */ "./views/admin/Mislaka"),
          meta: { title: "מסלקה | AddedValue" }
        },
        {
          path: "mislaka/:report",
          name: "admin-mislaka-report",
          component: () =>
            import(/* webpackChunkName: "admin" */ "./views/admin/MislakaFile")
        },
        {
          path: "mislaka/:report/modules",
          name: "admin-mislaka-report-modules",
          component: () =>
            import(
              /* webpackChunkName: "admin" */ "./views/admin/ClientReportModules"
            )
        },
        {
          path: "emails/",
          name: "admin-emails",
          component: () =>
            import(/* webpackChunkName: "admin" */ "./views/admin/Emails"),
          meta: { title: "מיילים | AddedValue" }
        },
        {
          path: "client/:id",
          name: "admin-client",
          component: () =>
            import(/* webpackChunkName: "admin" */ "./views/admin/Client")
        },
        {
          path: "client/:id/report/:report",
          name: "admin-client-report",
          component: () =>
            import(/* webpackChunkName: "admin" */ "./views/admin/ClientReport")
        },
        {
          path: "filters",
          name: "admin-filters",
          component: () =>
            import(/* webpackChunkName: "admin" */ "./views/admin/Filters"),
          meta: { title: "filters | AddedValue" }
        },
        {
          path: "hanmaka",
          name: "admin-hanmaka",
          component: () =>
            import(/* webpackChunkName: "admin" */ "./views/admin/Hanmaka"),
          meta: { title: "הנמקה | AddedValue" }
        },
        {
          path: "client/:id/report/:report/modules",
          name: "admin-client-report-modules",
          component: () =>
            import(
              /* webpackChunkName: "admin" */ "./views/admin/ClientReportModules"
            ),
          meta: { title: "ניתוח דוח | AddedValue" }
        },
        {
          path: "client/:id/report/:report/dashboard*",
          name: "admin-client-report-dashboard-preview",
          component: () =>
            import(
              /* webpackChunkName: "admin" */ "./views/admin/ClientDashboardPreview"
            ),
          meta: { title: "ממשק למשתמש | AddedValue" }
        },
        {
          path: "client/:id/report/:report/edit-dashboard",
          name: "admin-client-report-dashboard-edit",
          component: () =>
            import(
              /* webpackChunkName: "admin" */ "./views/admin/ClientDashboardEditor"
            ),
          meta: { title: "עריכת ממשק למשתמש | AddedValue" }
        },
        {
          path: "ranking",
          name: "admin-ranking",
          component: () =>
            import(/* webpackChunkName: "admin" */ "./views/admin/Ranking"),
          meta: { title: "דירוג קופות | AddedValue" }
        },
        {
          path: "forms",
          name: "admin-forms",
          component: () =>
            import(/* webpackChunkName: "admin" */ "./views/admin/Forms"),
          meta: { title: "טפסים | AddedValue" }
        },
        {
          path: "reminders",
          name: "admin-reminders",
          component: () =>
            import(/* webpackChunkName: "admin" */ "./views/admin/Reminders"),
          meta: { title: "תזכורות | AddedValue" }
        },
        {
          path: "tooltips",
          name: "admin-tooltips",
          component: () =>
            import(/* webpackChunkName: "admin" */ "./views/admin/Tooltips"),
          meta: { title: "הסברים ללקוח | AddedValue" }
        },
        {
          path: "search",
          name: "admin-search",
          component: () =>
            import(/* webpackChunkName: "admin" */ "./views/admin/Search"),
          meta: { title: "חיפוש בקבצים | AddedValue" }
        },
        {
          path: "performance",
          name: "admin-performance",
          component: () =>
            import(/* webpackChunkName: "admin" */ "./views/admin/Performance"),
          meta: { title: "ביצועי מערכת | AddedValue" }
        },
        {
          path: "flows",
          name: "admin-flows",
          component: () =>
            import(/* webpackChunkName: "admin" */ "./views/admin/Flows"),
          meta: { title: "תהליכים | AddedValue" }
        },
        {
          path: "audit",
          name: "admin-audit",
          component: () =>
            import(/* webpackChunkName: "admin" */ "./views/admin/Audit"),
          meta: { title: "מעקב פעילות למערכת | AddedValue" }
        }
        // { path: '/settings', component: Settings },
      ]
    },
    {
      path: "/print",
      component: PrintLayout,
      children: [
        {
          path: "client/:id/report/:report/hanmaka",
          name: "print-client-hanmaka",
          component: () =>
            import(/* webpackChunkName: "print" */ "./views/print/Hanmaka")
        },
        {
          path: "client/:id/report/:report/client",
          name: "print-client-report",
          component: () =>
            import(
              /* webpackChunkName: "print" */ "./views/print/ClientReport"
            )
        }
      ]
    },
    {
      path: "/register",
      component: Register,
      meta: {
        title: "AddedValue - אימות לקוח",
        menus: false
      },
      children: [
        {
          name: "register",
          path: "",
          component: () =>
            import(
              /* webpackChunkName: "register" */ "./views/register/RegisterStart"
            )
        },
        {
          name: "register-step-details",
          path: "details",
          component: () =>
            import(
              /* webpackChunkName: "register" */ "./views/register/RegisterDetails"
            )
        },
        {
          name: "register-step-verify",
          path: "verification",
          component: () =>
            import(
              /* webpackChunkName: "register" */ "./views/register/RegisterVerify"
            )
        },
        {
          name: "register-step-mislaka-authorize",
          path: "mislaka-authorize",
          component: () =>
            import(
              /* webpackChunkName: "register" */ "./views/register/RegisterMislakaAuthorization"
            )
        },
        {
          name: "register-step-purchase",
          path: "purchase",
          component: () =>
            import(
              /* webpackChunkName: "register" */ "./views/register/RegisterPurchase"
            )
        },
        {
          name: "register-step-login",
          path: "authenticate",
          component: () =>
            import(
              /* webpackChunkName: "register" */ "./views/register/RegisterLogin"
            )
        },
        {
          name: "register-step-pdf-auth",
          path: "authorization",
          component: () =>
            import(
              /* webpackChunkName: "register" */ "./views/register/RegisterAuthorization"
            )
        },
        {
          name: "register-step-upload-id",
          path: "upload-documents",
          component: () =>
            import(
              /* webpackChunkName: "register" */ "./views/register/RegisterUploadId"
            )
        },
        {
          name: "register-complete",
          path: "complete",
          component: () =>
            import(
              /* webpackChunkName: "register" */ "./views/register/RegisterComplete"
            )
        }
      ]
    },
    {
      /* used for credit-card iframe forward validation events */
      path: "/verification/success",
      component: Empty,
      meta: {
        title: "AddedValue - אימות לקוח"
      }
    },
    {
      /* used for credit-card iframe forward validation events */
      path: "/verification/failed",
      component: Empty,
      meta: {
        title: "AddedValue - אימות לקוח נכשל"
      }
    },
    {
      meta: {
        title: "AddedValue - טפסים לחתימה",
        menus: false
      },
      path: "/sign-package/:packageId",
      component: () =>
        import(/* webpackChunkName: "sign" */ "./views/SignPackageLayout"),
      children: [
        {
          path: "",
          name: "sign-welcome",
          component: () =>
            import(
              /* webpackChunkName: "sign" */ "./views/signPackage/Welcome"
            )
        },
        {
          path: "form/:formIndex",
          name: "sign-form",
          component: () =>
            import(
              /* webpackChunkName: "sign" */ "./views/signPackage/SignForm"
            )
        },
        {
          path: "complete",
          name: "sign-complete",
          component: () =>
            import(
              /* webpackChunkName: "sign" */ "./views/signPackage/Complete"
            )
        }
      ]
    },

    {
      path: "/demo/*",
      name: "demo-home",
      component: () =>
        import(/* webpackChunkName: "user" */ "./views/DemoLayout")
    },
    {
      path: "/my/*",
      name: "user-home",
      component: () =>
        import(/* webpackChunkName: "user" */ "./views/UserLayout"),

      children: [
        {
          path: "/my/account",
          name: "user-account",
          component: () =>
            import(/* webpackChunkName: "user" */ "./views/user/Account")
        },
        {
          path: "/my/messages/:mode",
          name: "user-messages",
          props: true,
          component: () =>
            import(/* webpackChunkName: "user" */ "./views/user/Messages")
        },
        {
          path: "/my/messages/:mode/:threadId",
          name: "user-message-thread",
          props: true,
          component: () =>
            import(/* webpackChunkName: "user" */ "./views/user/Messages")
        }
      ]
    },
    //TODO: consider removing this route - but it is good for testing
    {
      path: "/site/*",
      name: "site-home",
      component: () =>
        import(/* webpackChunkName: "site" */ "./views/SiteLayout"),
      children: [
        {
          path: "/",
          name: "site-homepage",
          props: true,
          component: () =>
            import(/* webpackChunkName: "site" */ "./views/site/Homepage")
        }
      ]
    },
    {
      path: "/site",
      redirect: "/site/"
    },
    {
      path: "*",
      redirect: "/my/"
    }
  ];
}

let router = new Router({
  mode: "history",
  routes: getRoutes(),
  scrollBehavior(to, from, scrollPosition) {
    if (to.hash && !to.hash.startsWith("#?")) {
      return {
        selector: to.hash,
        behavior: 'smooth',
        offset: { x: 0, y: to.hash.startsWith == '#top-page' ? 0 : 100 }
      };
    } else if (scrollPosition) {
      return scrollPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach((to, form, next) => {
  if (to.meta.title) {
    if (typeof to.meta.title == "function") {
      document.title = to.meta.title(to);
    } else {
      document.title = to.meta.title;
    }
  }
  next();
});

export default router;
